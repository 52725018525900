@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

:root {
  --primary-white: #fff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 59, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f35501;
  --bootrap-dark-navbar: #212529;
  --primary-black: #000000;
  --primary-green: #00ff00;
  --primary-dark: #161616;
  --offset-dark: #222222;
}

body {
  font-family: "Roboto Condensed", sans-serif !important;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
/************ NAVBAR ************/

nav {
  z-index: 2;
}

nav a.nav-link {
  font-size: 0.8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

p {
  color: #ccd1d9;
  font-size: 1.2em;
  line-height: 1.5;
}
.navbar-nav > li > a {
  color: var(--primary-light-grey) !important;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  border-top: 0.1875rem solid var(--bootrap-dark-navbar);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

.mainBackground {
  background-color: var(--primary-dark);
  min-width: 300px;
  overflow: hidden;
}

.offsetBackground {
  background-color: var(--offset-dark);
}
/************ HEADER ************/

.header-wrapper {
  position: relative;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
}

/************ TYPED-TEXT ************/

.typed-text {
  font-size: 2rem;
  color: var(--primary-white);
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/************ MAIN BUTTON OFFER ************/
.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-light-grey);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-light-grey);
  transition: 0.2s ease-in-out;
}

/************ MODAL ************/

.modal-text {
  color: var(--primary-light-grey);
}
/************ PARTICLES JS ************/

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vh;
  z-index: 1;
}

/************ ABOUT ME ************/

.about-heading {
  text-transform: uppercase;
  text-align: center;
  color: var(--primary-orange);
}

.about-paragraph {
  font-size: 20px;
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-light-grey);
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: var(--primary-orange);
}

@media (max-width: 468px) {
  .profile-img {
    size: 10rem;
  }
}

/************ SERVICES ************/

.services {
  text-align: center;
  padding-bottom: 2rem;
  min-width: 300px;
}

.services h1 {
  text-transform: uppercase;
  color: var(--primary-orange);
}

.services h3 {
  text-transform: uppercase;
  color: var(--primary-light-grey);
}
.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-light-grey);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 20rem;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 4rem;
  background: var(--offset-dark);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover p {
  color: var(--primary-white);
}

@media (max-width: 576px) {
  .services .box {
    height: 18rem;
  }
}

/************ EXPERIENCE ************/

.experience {
  padding: 0.7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-orange);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  margin-top: 0.625rem;
  z-index: 3;
  background: var(--primary-orange);
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-red);
  font-weight: 900;
}

@media (max-width: 768px) {
  .experience-wrapper {
    padding-left: 0;
  }

  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    width: 100%;
    direction: ltr;
  }
}

/************ PORTFOLIO ************/

.portfolio-btn {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-light-grey);
  margin: 0.5rem;
  background: none;
}

.portfolio-wrapper {
  padding: 3rem 0;
}

h3 {
  text-transform: uppercase;
  color: var(--primary-white);
}
.portfolio-wrapper h1 {
  color: var(--primary-orange);
}

.portfolio-image {
  width: 15rem;
  padding: 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 5px;
  z-index: 1;
  border: 0.06rem solid var(--primary-orange);
}

.portfolio-label {
  align-self: center;
  justify-self: center;
}

.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 40%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: relative;
  opacity: 0;
  height: 100%;
  left: calc(50% - 1rem);
  color: var(--primary-orange);
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 100% !important;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 4;
}

.portfolio-image-popupbox {
  width: 15%;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-orange);
}

.hyper-link:hover {
  cursor: pointer;
  color: var(--primary-red);
}

@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 12rem;
  }
}

@media (max-width: 500px) {
  .portfolio-image-popupbox {
    width: 10rem;
  }
}

/** ARTICLES **/

.articles {
  text-align: center;
}

.articles h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.article-card {
  cursor: pointer;
  transition: transform 0.3s ease;
  background: var(--primary-dark) !important;
}

.article-card:hover {
  transform: scale(1.03);
  border-radius: 5px;
  border-color: var(--primary-hover-red);
  background: var(--primary-orange) !important;
}

.article-card-image {
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.article-card-title {
  color: white;
}

.article-card-subtitle {
  color: gray;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/************ CONTACTME ************/

.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  width: 100%;
  background: none;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }
  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

/************ FOOTER ************/

.footer {
  background: var(--primary-dark);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
  color: var(--primary-dark-grey);
}

.footer a {
  color: var(--primary-dark-grey);
}

.dropdown-appear-above-content {
  position: relative;
  z-index: 5;
  overflow: visible;
}
